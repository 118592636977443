.contentBody{


}

.app{
  margin: 0px;
  padding: 0px;

  /*background-color: #ff8d8d;
  background-image: url(./Assets/Hjerte\ baggrund\ png.png);
  background-size: 100%;
  background-repeat: repeat-y;
  background-position-x: center;
*/

}

h1{
  font-size: 256px;
}

.contentHeader{

  text-align: center;
  align-content: center;
  top: 0;
}

.TillykkeText{
  text-align: center;
  font-size: 256px;
  font-style: italic;
  width: 100%;
}

#loveBackground{
  background-image: url('./Assets/Hjerte baggrund png.png');
  background-color: #ff8d8d;
  background-repeat: repeat-y
};

#gameBackground{
  background-repeat: repeat-y
};

.jagtenP{
  font-size: 256px;
};

#princessKittyGameBox{
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: transparent;
}